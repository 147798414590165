import React from 'react';
import Services from '../Services';
import Projects from '../Projects';
import Approach from '../Approach';
import Experience from '../Experience';
import Team from '../Team';

const MainLayout = () => {
  return (
    <main className='main'>
      <Services />
      {/* Временно убрать проекты */}
      {/* <Projects /> */}
      <Approach />
      <Experience />
      <Team />
    </main>
  );
};

export default MainLayout;
