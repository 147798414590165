import React from 'react';
import 'styles/components/layout/mainpage.scss';
import Header from '../Header';
import InfoTitles from 'components/ui/Titles/InfoTitles';

const MainScreen = () => {
  // https://youtu.be/oJ7GoAlzr2o
  return (
    <section className='mainpage'>
      <Header />

      <div
        className='video-container-player'
        dangerouslySetInnerHTML={{
          __html: `
                <video
                  loop
                  muted={true}
                  autoPlay
                  playsInline={true}
                  id="video"
                >
                <source autoPlay muted={true} src="city_video.mp4" type="video/mp4" />
                </video>`,
        }}
      />

      {/* <div className='video-container-player'>
        <video
          src='city_video.mp4'
          preload='metadata'
          width='100%'
          height='100%'
          autoPlay
          playsInline
          loop
          muted
        />
      </div> */}
      <InfoTitles />
    </section>
  );
};

export default MainScreen;
