import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/ui/cookiesconsent.scss';
import useMediaQuery from 'utils/useMediaQuery';

const CookiesConsentBanner = () => {
  const { t, i18n } = useTranslation();

  const downloadPdf = useCallback((filePath) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const [removeBanner, setRemoveBanner] = useState(false);

  const cookiesSetUp = localStorage.getItem('cookies_setup');

  // banner appears if cookiesSetUp

  const handleSetUpCookies = (accepted) => {
    localStorage.setItem('cookies_setup', true);
    setRemoveBanner(true);
  };

  const isMobile = useMediaQuery('(max-width: 601px)');

  console.log(`cookiesSetUp: ${cookiesSetUp !== true}`);

  return (
    !cookiesSetUp && (
      <section className={`cookies_consent ${removeBanner && 'banner-hidden'}`}>
        <article className='cookies_consent_descr'>
          <span>{t('cookies.description')}</span>
          <span
            onClick={() =>
              downloadPdf(
                `${i18n.language !== 'en' ? 'cookie_policy.docx' : 'cookie_policy_en.docx'}`,
              )
            }
          >
            {t('footer.contacts.privacy_text')}
          </span>
        </article>
        <div className='cookies_btns'>
          <button
            onClick={() => handleSetUpCookies(false)}
            className='cookies_btns_button decline'
          >
            {t('cookies.decline')}
          </button>
          <button
            onClick={() => handleSetUpCookies(true)}
            className='cookies_btns_button accept'
          >
            {isMobile ? t('cookies.accept_mobile') : t('cookies.accept')}
          </button>
        </div>
      </section>
    )
  );
};

export default CookiesConsentBanner;
