import DescriptionMain from 'components/ui/Descriptions/DescriptionMain';
import HeadlineLarge from 'components/ui/Headlines/HeadlineLarge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/main/team.scss';

const Team = () => {
  const { t, i18n } = useTranslation();

  const teamContent = t('main.team.content', { returnObjects: true });

  return (
    <section className='team' id='team'>
      <article className='team_title'>
        <HeadlineLarge text={t('main.team.title')} />
      </article>
      <div className='team_cards'>
        {teamContent.map((member) => (
          <div
            className={`team_cards_card ${i18n.language !== 'en' && 'hover'}`}
            key={member.id}
            style={member.id === 'empty' ? { border: 'unset' } : {}}
          >
            {member.id !== 'empty' && (
              <img src={`${member.id}.png`} alt={member.id} />
            )}
            <article className={`team_cards__descr`}>
              <h2 style={{ margin: '10px 0' }}>{member.name}</h2>
              <span>{member.position}</span>
              {i18n.language !== 'en' && <p lang='ru'>{member.description}</p>}
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
