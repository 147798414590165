import DescriptionMain from 'components/ui/Descriptions/DescriptionMain';
import HeadlineLarge from 'components/ui/Headlines/HeadlineLarge';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/ui/headlines.scss';
import 'styles/components/layout/main/services.scss';
import {
  CostEvalIcon,
  DropDownServicesIcon,
  EduProgramIcon,
  FinancialModelIcon,
  FundraisingIcon,
  StrategicPlanningIcon,
} from 'components/ui/Icons/Services';
import useMediaQuery from 'utils/useMediaQuery';

const Services = () => {
  const { t, i18n } = useTranslation();

  const servicesContent = t('main.services.content', { returnObjects: true });

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [flippedItems, setFlippedItems] = useState({});

  const handleClick = (id) => {
    setFlippedItems((prevFlippedItems) => ({
      ...prevFlippedItems,
      [id]: !prevFlippedItems[id],
    }));
  };

  const renderIcon = (id) => {
    switch (id) {
      case 'costs_evaluation':
        return <CostEvalIcon fill={'#A62020'} />;
        break;
      case 'strategic_planning':
        return <StrategicPlanningIcon fill={'#A62020'} />;
        break;
      case 'financial_modeling':
        return <FinancialModelIcon fill={'#A62020'} />;
        break;
      case 'fundraising':
        return <FundraisingIcon fill={'#A62020'} />;
        break;
      case 'educational_programs':
        return <EduProgramIcon fill={'#A62020'} />;
        break;
    }
  };

  return (
    <section className='services' id='services'>
      <article className='services_title'>
        <HeadlineLarge text={t('main.services.title')} />
        <DescriptionMain text={t('main.services.title_description')} />
      </article>
      <ul className='services_list'>
        {servicesContent.map((service, index) => (
          <section className='hover_wrapper' key={service.id}>
            {/* <div className='service-icon'>{renderIcon(service.id)}</div> */}
            <li
              className={`services_list_content`}
              id={flippedItems[service.id] ? 'flipped' : ''}
            >
              {!isMobile && (
                <div className='service-icon'>{renderIcon(service.id)}</div>
              )}
              <div
                style={{
                  backgroundImage: `url(${service.id}.png)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'bottom',
                }}
                className='services_list_content_imageContainer hover_container'
              >
                {/* <div className='service-icon'></div> */}
              </div>
              <div
                id='description'
                className='services_list_content_description'
                onClick={() => handleClick(service.id)}
              >
                <article className='descr-header'>
                  {isMobile && <div className=''>{renderIcon(service.id)}</div>}
                  <h3 className={'headline__medium'}>{service.title}</h3>
                  {isMobile && (
                    <div
                      id='arrow-icon'
                      className={flippedItems[service.id] ? 'flipped' : ''}
                    >
                      <DropDownServicesIcon />
                    </div>
                  )}
                </article>
                <div className={'hover_container'}>
                  <DescriptionMain text={service.description} />
                  {service.articles.map((article, index) => (
                    <div
                      className={`services_list_content_description__text ${i18n.language === 'en' && 'list-en'}`}
                      key={article.title}
                    >
                      {i18n.language !== 'en' && (
                        <h3 className={'headline__small'}>{article.title}</h3>
                      )}
                      <ul>
                        {article.list.map((item, index) => (
                          <li
                            className={`${i18n.language !== 'en' ? 'descr-item' : 'descr-item_en'}`}
                            key={item}
                          >
                            <DescriptionMain text={item} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </li>
          </section>
        ))}
      </ul>
    </section>
  );
};

export default Services;
