import React from 'react';
import './App.css';
import MainScreen from 'components/layout/MainScreen';
import Footer from 'components/layout/Footer';
import MainLayout from 'components/layout/MainLayout';
import CookiesConsentBanner from 'components/ui/CookiesConsentBanner';

function App() {
  return (
    <div>
      <MainScreen />
      <MainLayout />
      <Footer />
      <CookiesConsentBanner />
    </div>
  );
}
export default App;
