import React from 'react';

const TelegramLinkIcon = ({ t }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <title id='social_title'>{t('footer.link_descr_telegram')}</title>
      <path
        d='M30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM43.92 20.4C43.47 25.14 41.52 36.66 40.53 41.97C40.11 44.22 39.27 44.97 38.49 45.06C36.75 45.21 35.43 43.92 33.75 42.81C31.11 41.07 29.61 39.99 27.06 38.31C24.09 36.36 26.01 35.28 27.72 33.54C28.17 33.09 35.85 26.1 36 25.47C36.0208 25.3746 36.0181 25.2755 35.9919 25.1814C35.9658 25.0873 35.9171 25.001 35.85 24.93C35.67 24.78 35.43 24.84 35.22 24.87C34.95 24.93 30.75 27.72 22.56 33.24C21.36 34.05 20.28 34.47 19.32 34.44C18.24 34.41 16.2 33.84 14.67 33.33C12.78 32.73 11.31 32.4 11.43 31.35C11.49 30.81 12.24 30.27 13.65 29.7C22.41 25.89 28.23 23.37 31.14 22.17C39.48 18.69 41.19 18.09 42.33 18.09C42.57 18.09 43.14 18.15 43.5 18.45C43.8 18.69 43.89 19.02 43.92 19.26C43.89 19.44 43.95 19.98 43.92 20.4Z'
        fill='white'
      />
    </svg>
  );
};

export default TelegramLinkIcon;
