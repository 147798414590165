import DescriptionMain from 'components/ui/Descriptions/DescriptionMain';
import HeadlineLarge from 'components/ui/Headlines/HeadlineLarge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/main/experience.scss';

const Experience = () => {
  const { t } = useTranslation();

  const logoContent = t('main.experience.content', { returnObjects: true });

  return (
    <section className='experience' id='experience'>
      <article className='experience_title'>
        <HeadlineLarge text={t('main.experience.title')} />
        <DescriptionMain text={t('main.experience.title_description')} />
      </article>
      <div className='experience_logos'>
        {logoContent.map((logo) => (
          <a
            key={logo.name}
            href={logo.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={`${logo.name}.svg`} alt={logo.name} />
          </a>
        ))}
      </div>
    </section>
  );
};

export default Experience;
