import { SuccessIconForm } from 'components/ui/Icons/FeedbackForm';
import TelegramLinkIcon from 'components/ui/Links/TelegramLinkIcon';
import WhatsappLinkIcon from 'components/ui/Links/WhatsappLinkIcon';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/footer.scss';

const Footer = () => {
  const { t, i18n } = useTranslation();

  const downloadPdf = useCallback((filePath) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const [result, setResult] = useState('');
  const key = process.env.REACT_APP_FORM_ACCESS_KEY;

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult('Sending....');
    const formData = new FormData(event.target);

    formData.append('access_key', key);

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult('Form Submitted Successfully');
      event.target.reset();
    } else {
      console.log('Error', data);
      setResult(data.message);
    }
  };

  return (
    <footer className='footer'>
      <section className='footer_feedback' id='contacts'>
        <section className='footer_feedback_description'>
          <article className='footer_feedback_description__contacts'>
            <h1>{t('footer.title')}</h1>
            <h2>{t('footer.subtitle')}</h2>
          </article>
          <article className='footer_feedback_description__links'>
            <p>{t('footer.contact_subtitle')}</p>
            <div className='footer_feedback_description__links__icons'>
              <a
                href='https://wa.me/79689627071'
                target='_blank'
                className='footer_feedback_description__contacts__social'
                rel='noreferrer'
              >
                <WhatsappLinkIcon t={t} />
              </a>
              <a
                href='https://t.me/Niyaz_Faridovich'
                target='_blank'
                className='footer_feedback_description__contacts__social'
                rel='noreferrer'
              >
                <TelegramLinkIcon t={t} />
              </a>
            </div>
          </article>
        </section>
        <section className='footer_feedback_form'>
          <form onSubmit={onSubmit}>
            <input
              type='text'
              name='name'
              required
              placeholder={t('form.input_placeholder_name')}
            ></input>
            <input
              type='text'
              name='email'
              required
              placeholder={t('form.input_placeholder_email')}
            ></input>
            <input
              type='text'
              name='phone'
              required
              placeholder={t('form.input_placeholder_phone')}
            ></input>
            <textarea
              name='message'
              placeholder={t('form.input_placeholder_comment')}
            ></textarea>
            <span>
              {t('form.personal_info_agreement')}
              <span
                onClick={() =>
                  downloadPdf(
                    `${i18n.language !== 'en' ? 'personal_info_collection_agreement.docx' : 'personal_info_collection_agreement_en.docx'}`,
                  )
                }
                className='agreement-link'
              >
                {t('form.personal_info_agreement_link')}
              </span>
            </span>
            <button type='submit'>
              {t('form.button_text')}{' '}
              {result === 'Form Submitted Successfully' && (
                <div className='success-icon'>
                  <SuccessIconForm />
                </div>
              )}
            </button>
          </form>
        </section>
      </section>
      <section className='footer_contacts' id='contacts_info'>
        <article className='footer_contacts_title'>
          <h1>{t('footer.contact_title')}</h1>
        </article>

        {/* временно убрать телефон */}
        {/* <article>
          <h2>{t('footer.contacts.phone_header')}</h2>
          <span>{t('footer.contacts.phone_text')}</span>
        </article> */}

        <article>
          <h2>{t('footer.contacts.email_header')}</h2>
          <span>{t('footer.contacts.email_text')}</span>
        </article>

        <article>
          <h2>{t('footer.contacts.schedule_header')}</h2>
          <span>{t('footer.contacts.schedule_text')}</span>
        </article>

        <article></article>

        <article></article>

        <article>
          <span>{t('footer.contacts.privacy_header')}</span>
          <span
            onClick={() =>
              downloadPdf(
                `${i18n.language !== 'en' ? 'arrowhead_privacy_policy.docx' : 'arrowhead_privacy_policy_en.docx'}`,
              )
            }
            className='agreement-link'
          >
            {t('footer.contacts.privacy_text')}
          </span>
        </article>
      </section>
    </footer>
  );
};

export default Footer;
