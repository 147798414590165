import React from 'react';

export const SuccessIconForm = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
    >
      <path
        d='M16.9579 29.9579L4 17'
        stroke='#4ED664'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 29.9896L35.9896 11'
        stroke='#4ED664'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
