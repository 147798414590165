import React from 'react';

export const MobileMenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
    >
      <rect y='7' width='42' height='1' fill='rgba(37, 38, 43, 1)' />
      <rect y='20' width='42' height='1' fill='rgba(37, 38, 43, 1)' />
      <rect y='33' width='42' height='1' fill='rgba(37, 38, 43, 1)' />
    </svg>
  );
};

export const MobileMenuIconClose = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <path
        d='M1.30435 6.04715e-06L60 58.6956L58.6956 60L0 1.30435L1.30435 6.04715e-06Z'
        fill='#9EA2A6'
      />
      <path
        d='M2.67174e-05 58.6956L58.6957 0L60 1.30435L1.30437 60L2.67174e-05 58.6956Z'
        fill='#9EA2A6'
      />
    </svg>
  );
};
