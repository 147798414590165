import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/ui/infotitle.scss';

const InfoTitles = () => {
  const { t } = useTranslation();

  const firstLineTitles = t('info_titles.first_line', { returnObjects: true });
  console.log(`LOCAL: ${firstLineTitles}`);

  return firstLineTitles.map((title, index) => {
    return (
      <section key={index} className='info_title'>
        <h1 className={`info_title__${index}`}>
          {title}{' '}
          <span>
            {t(`info_titles.second_line.${[index]}`, { returnObjects: true })}
          </span>
        </h1>
      </section>
    );
  });
};

export default InfoTitles;
