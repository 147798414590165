import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const currentLanguage = localStorage.getItem('language') || 'ru';

  const changeLanguage = (language) => {
    console.log('language', language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <nav className='language-switcher'>
      <button
        aria-label={currentLanguage === 'ru' ? 'active' : ''}
        onClick={() => changeLanguage('ru')}
      >
        РУ
      </button>
      <button
        aria-label={currentLanguage === 'en' ? 'active' : ''}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
    </nav>
  );
};

export default LanguageSwitcher;
