import React, { useState } from 'react';
import TelegramLinkIcon from '../Links/TelegramLinkIcon';
import WhatsappLinkIcon from '../Links/WhatsappLinkIcon';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/footer.scss';
import { MobileMenuIconClose } from '../Buttons/MobileMenuIcon';
import { SuccessIconForm } from '../Icons/FeedbackForm';

const FeedbackForm = ({ className, handleCloseFeedbackForm }) => {
  const { t } = useTranslation();

  const [result, setResult] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const key = process.env.REACT_APP_FORM_ACCESS_KEY;

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSuccess(true);
    setResult('Sending....');
    const formData = new FormData(event.target);

    formData.append('access_key', key);

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult('Form Submitted Successfully');
      event.target.reset();
    } else {
      console.log('Error', data);
      setResult(data.message);
    }
  };

  return (
    <div className={className}>
      <button className='mobile-burger-close' onClick={handleCloseFeedbackForm}>
        <MobileMenuIconClose />
      </button>
      <section className='footer_feedback'>
        <section className='footer_feedback_description'>
          <article className='footer_feedback_description__contacts'>
            <h1>{t('footer.title')}</h1>
            <h2>{t('footer.subtitle')}</h2>
          </article>
          <article className='footer_feedback_description__links'>
            <p>{t('footer.contact_subtitle')}</p>
            <div className='footer_feedback_description__links__icons'>
              <a
                href='https://wa.me/79689627071'
                target='_blank'
                className='footer_feedback_description__contacts__social'
                rel='noreferrer'
              >
                <WhatsappLinkIcon t={t} />
              </a>
              <a
                href='https://t.me/Niyaz_Faridovich'
                target='_blank'
                className='footer_feedback_description__contacts__social'
                rel='noreferrer'
              >
                <TelegramLinkIcon t={t} />
              </a>
            </div>
          </article>
        </section>
        <section className='footer_feedback_form'>
          <form onSubmit={onSubmit}>
            <input placeholder={t('form.input_placeholder_name')}></input>
            <input placeholder={t('form.input_placeholder_email')}></input>
            <input placeholder={t('form.input_placeholder_phone')}></input>
            <textarea
              placeholder={t('form.input_placeholder_comment')}
            ></textarea>
            <span>
              {t('form.personal_info_agreement')}
              <span
                onClick={() =>
                  downloadPdf(`personal_info_collection_agreement.pdf`)
                }
                className='agreement-link'
              >
                {t('form.personal_info_agreement_link')}
              </span>
            </span>
            <button
              type='submit'
              className={isSuccess ? 'arrow-show-false' : 'arrow-show-true'}
            >
              {t('form.button_text')}{' '}
              {result === 'Form Submitted Successfully' && (
                <div className='success-icon-inner'>
                  <SuccessIconForm />
                </div>
              )}
            </button>
          </form>
        </section>
      </section>
    </div>
  );
};

export default FeedbackForm;
