import React from 'react';

export const CostEvalIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <g clipPath='url(#clip0_906_455)'>
        <path
          d='M3.74669e-07 6.20343e-05H8.57143V22.8572H3.74669e-07V6.20343e-05Z'
          fill={fill}
        />
        <path
          d='M60 6.20343e-05V8.57149L37.1429 8.57149V6.10352e-05L60 6.20343e-05Z'
          fill={fill}
        />
        <path
          d='M3.74669e-07 60.0001L0 51.4286H22.8571V60.0001H3.74669e-07Z'
          fill={fill}
        />
        <path d='M60 60.0001H51.4286L51.4286 37.1429H60V60.0001Z' fill={fill} />
        <path
          d='M22.8571 6.20343e-05V8.57149L0 8.57149L3.74669e-07 6.20343e-05H22.8571Z'
          fill={fill}
        />
        <path
          d='M60 22.8572L51.4286 22.8572V6.12849e-05L60 6.20343e-05V22.8572Z'
          fill={fill}
        />
        <path
          d='M3.74669e-07 37.1429H8.57143V60.0001H3.74669e-07V37.1429Z'
          fill={fill}
        />
        <path d='M37.1429 60.0001V51.4286H60V60.0001H37.1429Z' fill={fill} />
      </g>
      <defs>
        <clipPath id='clip0_906_455'>
          <rect width='60' height='60' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FinancialModelIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <g clipPath='url(#clip0_906_491)'>
        <path d='M60 6.10352e-05H30L60 30.0001V6.10352e-05Z' fill={fill} />
        <path
          d='M44.2857 14.2858H14.2857L44.2857 44.2858V14.2858Z'
          fill={fill}
        />
        <path d='M30 30.0001H0L30 60.0001V30.0001Z' fill={fill} />
      </g>
      <defs>
        <clipPath id='clip0_906_491'>
          <rect width='60' height='60' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StrategicPlanningIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <g clipPath='url(#clip0_906_533)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30 60.0001C46.5685 60.0001 60 46.5686 60 30.0001C60 13.4315 46.5685 6.10352e-05 30 6.10352e-05C13.4315 6.10352e-05 0 13.4315 0 30.0001C0 46.5686 13.4315 60.0001 30 60.0001ZM30 60.0001C30 41.4286 41.4286 30.0001 60 30.0001C41.4286 30.0001 30 18.5715 30 6.10352e-05C30 18.5715 18.5714 30.0001 0 30.0001C18.5714 30.0001 30 41.4286 30 60.0001Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_906_533'>
          <rect width='60' height='60' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FundraisingIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <path
        d='M60 20.0001C60 17.3736 59.4827 14.7729 58.4776 12.3464C57.4725 9.91988 55.9993 7.7151 54.1421 5.85792C52.285 4.00075 50.0802 2.52756 47.6537 1.52247C45.2271 0.517376 42.6264 6.09204e-05 40 6.10352e-05C37.3736 6.115e-05 34.7728 0.517377 32.3463 1.52247C29.9198 2.52757 27.715 4.00075 25.8579 5.85793C24.0007 7.7151 22.5275 9.91988 21.5224 12.3464C20.5173 14.7729 20 17.3736 20 20.0001L40 20.0001H60Z'
        fill={fill}
      />
      <path
        d='M20 9.06785e-05C17.3736 9.07933e-05 14.7728 0.517406 12.3463 1.5225C9.91982 2.5276 7.71504 4.00079 5.85786 5.85796C4.00069 7.71513 2.5275 9.91991 1.52241 12.3464C0.517315 14.7729 -2.2961e-07 17.3737 0 20.0001C2.29611e-07 22.6265 0.517316 25.2272 1.52241 27.6538C2.5275 30.0803 4.00069 32.2851 5.85787 34.1422C7.71504 35.9994 9.91982 37.4726 12.3463 38.4777C14.7728 39.4828 17.3736 40.0001 20 40.0001L20 20.0001L20 9.06785e-05Z'
        fill={fill}
      />
      <path
        d='M40 60C42.6264 60 45.2272 59.4827 47.6537 58.4776C50.0802 57.4725 52.285 55.9993 54.1421 54.1422C55.9993 52.285 57.4725 50.0802 58.4776 47.6537C59.4827 45.2272 60 42.6265 60 40C60 37.3736 59.4827 34.7729 58.4776 32.3463C57.4725 29.9198 55.9993 27.7151 54.1421 25.8579C52.285 24.0007 50.0802 22.5275 47.6537 21.5224C45.2272 20.5173 42.6264 20.0001 40 20.0001L40 40V60Z'
        fill={fill}
      />
      <path
        d='M1.40668e-05 40.0001C1.38372e-05 42.6265 0.517329 45.2272 1.52242 47.6537C2.52752 50.0802 4.00071 52.285 5.85788 54.1422C7.71505 55.9994 9.91983 57.4726 12.3463 58.4776C14.7729 59.4827 17.3736 60.0001 20 60.0001C22.6264 60.0001 25.2272 59.4827 27.6537 58.4776C30.0802 57.4726 32.285 55.9994 34.1422 54.1422C35.9993 52.285 37.4725 50.0802 38.4776 47.6537C39.4827 45.2272 40 42.6265 40 40L20 40.0001L1.40668e-05 40.0001Z'
        fill={fill}
      />
    </svg>
  );
};

export const EduProgramIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <path
        d='M60 4.00006C60 20.5686 46.5685 34.0001 30 34.0001C30 15.4286 41.4286 4.00006 60 4.00006Z'
        fill={fill}
      />
      <path
        d='M0 4.00006C18.5714 4.00006 30 15.4286 30 34.0001C13.4315 34.0001 0 20.5686 0 4.00006Z'
        fill={fill}
      />
      <path
        d='M60 30.0001C60 46.5686 46.5685 60.0001 30 60.0001C30 41.4286 41.4286 30.0001 60 30.0001Z'
        fill={fill}
      />
      <path
        d='M0 30.0001C18.5714 30.0001 30 41.4286 30 60.0001C13.4315 60.0001 0 46.5686 0 30.0001Z'
        fill={fill}
      />
    </svg>
  );
};

export const DropDownServicesIcon = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='57'
      height='56'
      viewBox='0 0 57 56'
      fill='none'
    >
      <path
        d='M57 42L29 15L1 42'
        stroke='#25262B'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
