import React from 'react';

const WhatsappLinkIcon = ({ t }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <title id='social_title'>{t('footer.link_descr_whatsapp')}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9911 1.01188e-05C46.3563 -0.0135787 60 13.6613 60 29.9996C60 46.3515 46.3563 59.9856 29.9911 59.9765C25.6905 59.9788 21.4394 59.0582 17.5247 57.2769L2.67199 59.9629C2.27773 60.0352 1.87136 60.0014 1.49441 59.8651C1.11747 59.7288 0.783448 59.4948 0.526487 59.1871C0.269525 58.8794 0.0988312 58.509 0.031844 58.1137C-0.0351431 57.7184 0.00397804 57.3124 0.145211 56.9372L4.40179 45.6222C1.53409 40.914 0.0146546 35.5083 0.00936219 29.9951C0.0138905 13.6568 13.644 0.00906933 29.9911 1.01188e-05ZM45.0476 42.7414C41.7103 45.441 36.5435 48.1679 31.6032 44.9971C25.1277 40.8344 19.3949 35.3672 15.238 28.8853C12.7972 25.0895 15.0931 19.0108 18.5255 15.3056C19.893 13.8244 22.1481 14.0599 23.6379 15.4188L26.7715 18.2815C27.7224 19.1512 27.7587 20.6641 27.1654 21.8146C26.2281 23.6174 25.6756 26.0407 26.8349 27.848C28.293 30.1128 30.0862 32.3459 34.3699 33.6459C35.665 34.0445 37.3269 33.8724 38.7397 33.3017C39.9216 32.8215 41.4205 32.8261 42.2944 33.7592L45.2106 36.8619C46.8182 38.5741 46.8725 41.2647 45.0476 42.7414Z'
        fill='white'
      />
    </svg>
  );
};

export default WhatsappLinkIcon;
