import DescriptionMain from 'components/ui/Descriptions/DescriptionMain';
import HeadlineLarge from 'components/ui/Headlines/HeadlineLarge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/main/approach.scss';

const Approach = () => {
  const { t, i18n } = useTranslation();

  const approachContent = t('main.approach.content', { returnObjects: true });

  return (
    <section className='approach'>
      <article className='approach_title'>
        <HeadlineLarge text={t('main.approach.title')} />
        {i18n.language !== 'en' ? (
          <ul className='approach_title_list'>
            {approachContent?.map((item, index) => (
              <li
                className='approach_title_list__item description__main'
                key={item}
              >
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <DescriptionMain text={t('main.approach.content')} />
        )}
      </article>
    </section>
  );
};

export default Approach;
