import React, { useState } from 'react';
import LanguageSwitcher from 'components/ui/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import 'styles/components/layout/header.scss';
import {
  MobileMenuIcon,
  MobileMenuIconClose,
} from 'components/ui/Buttons/MobileMenuIcon';
import useMediaQuery from 'utils/useMediaQuery';
import FeedbackForm from 'components/ui/FeedbackForm';

const Header = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [isOpen, setIsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenFeedbackForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseFeedbackForm = () => {
    setIsFormOpen(false);
  };

  return (
    <header className='header'>
      <div className='header__logo'>
        <a href='#'>
          <img src='arrowhead_header_logo_black.svg' alt='arrowheads_logo' />
        </a>
      </div>
      <nav
        className='header__nav'
        role='navigation'
        aria-label='Main Navigation'
      >
        <ul>
          <li>
            <a href='#services'>{t('nav_links.services')}</a>
          </li>
          <li>
            <a href='#experience'>{t('nav_links.we_are_trusted')}</a>
          </li>
          <li>
            <a href='#team'>{t('nav_links.team')}</a>
          </li>
        </ul>
      </nav>
      <div className='header__actions'>
        <button className='feedback-button' onClick={handleOpenFeedbackForm}>
          {t('nav_links.leave_feedback')}
        </button>
        <LanguageSwitcher />
        <button className='mobile-burger' onClick={handleOpen}>
          <MobileMenuIcon />
        </button>
        {/* <BurgerMenu /> */}
      </div>
      <FeedbackForm
        className={`feedback-form-main ${isFormOpen ? 'open' : ''}`}
        handleCloseFeedbackForm={handleCloseFeedbackForm}
      />
      {isMobile && (
        <nav
          className={`header__nav_mobile ${isOpen ? 'open' : ''}`}
          role='navigation'
          aria-label='Main Navigation dropdown menu'
        >
          <button className='mobile-burger' onClick={handleClose}>
            <MobileMenuIconClose />
          </button>
          <ul>
            <li>
              <a onClick={handleClose} href='#services'>
                {t('nav_links.services')}
              </a>
            </li>
            {/* <li>
              <a onClick={handleClose} href='#projects'>
                {t('nav_links.projects')}
              </a>
            </li> */}
            <li>
              <a onClick={handleClose} href='#experience'>
                {t('nav_links.experience')}
              </a>
            </li>
            <li>
              <a onClick={handleClose} href='#team'>
                {t('nav_links.team')}
              </a>
            </li>
            <li>
              <a onClick={handleClose} href='#contacts_info'>
                {t('nav_links.contacts')}
              </a>
            </li>
            <li>
              <a onClick={handleClose} href='#contacts'>
                {t('nav_links.leave_feedback')}
              </a>
            </li>
          </ul>
          <LanguageSwitcher />
        </nav>
      )}
    </header>
  );
};

export default Header;
